export const downloadPartStepFile = (partId: string | number): void => {
  fetch(`/api/v3/parts/${partId}/step_file`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
  })
    .then(async response => {
      const responseJson = await response.json()

      if (response.status === 401 && !responseJson.message) {
        window.location.href = '/users/sign_in'
      } else if (responseJson.error || responseJson.message) {
        toastr.error(responseJson.error?.message || responseJson.message)
      } else if (responseJson.data.url) {
        window.open(responseJson.data.url)
      }
    })
    .catch(error => console.error(error))
}
